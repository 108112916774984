const urls = {
  index: '/',
  livePreviews: '/live-previews',
  portfolio: '/portfolio/:year',
  external: {
    github: 'https://github.com/shilangyu',
    materialui: 'https://material-ui.com',
    p5: 'http://p5js.org',
    drawio: 'https://www.draw.io/',
    react: 'http://reactjs.org',
    livePreviews: {
      tictactoe: '/TicTacToe',
      geneticts: '/genetic.ts',
    },
  },
}

export default urls
